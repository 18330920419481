import { useCallback, useContext, useState } from 'react';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Radio from '@guestyci/foundation/Radio';
import { Col, Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro';

import PriceConverter from 'components/PriceConverter';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import CancellationPolicyText from 'constants/cancellationPolicy';
import CancellationPolicyKeys from 'constants/cancellationPolicyKeys';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratePlanItem: {
    margin: '5px 0px',
    border: '1px solid #D4D8E1',
    cursor: 'pointer',
    position: 'relative',
    marginLeft: 30,
    padding: 10,
    '&[data-chosen="true"]': {
      border: `1px solid`,
      borderColor: ({ colors }) => colors?.mainButtonsAndLinks || '#1BBCEF',
      background: ({ colors }) => colors?.mainButtonsAndLinks || '#1BBCEF',
      color: '#fff',
      '& div': {
        color: '#fff',
      },
    },
  },
  radioButton: {
    '& button': {
      position: 'absolute',
      right: '100%',
      marginRight: 10,
    },
    '& .text-field-wrapper': {
      width: '100%',
    },
  },
  ratePlanName: {
    padding: 10,
  },
  price: {
    textAlign: 'right',
  },
}));

const getRefundTitle = ({ cancellationFee, cancellationPolicy }) => {
  const policyText = CancellationPolicyText()[cancellationPolicy];
  if (cancellationPolicy === CancellationPolicyKeys.FREE) {
    return t('Free cancellation {policyText}', {
      policyText,
    });
  }
  if (cancellationPolicy === CancellationPolicyKeys.NON_REFUNDABLE) {
    return t('No refund upon cancellation {policyText}', {
      policyText,
    });
  }
  switch (cancellationFee) {
    case 0:
      return t('First night fee {policyText}', {
        policyText,
      });
    case 100:
      return t('No refund upon cancellation {policyText}', {
        policyText,
      });
    default:
      return t('{cancellationFee}% non-refundable {policyText}', {
        policyText,
        cancellationFee,
      });
  }
};

const getRules = ({ mealPlans }) => (mealPlans.length ? [`${mealPlans.join(', ').replaceAll('_', ' ')} included`] : []);
const getIsStandardPlanTitleHidden = ({ ratePlan }) =>
  ratePlan?._id === 'default-rateplan-id' && ratePlan?.cancellationPolicy == null && ratePlan?.cancellationFee == null;

const Rules = ({ rules, cancellationPolicy, cancellationFee }) => {
  return (
    (rules.length || cancellationPolicy) && (
      <ul>
        {cancellationFee !== '' && (
          <li>
            <TextField color="secondary">{getRefundTitle({ cancellationFee, cancellationPolicy })}</TextField>
          </li>
        )}
        {rules.length > 0 &&
          rules.map(
            (rule) =>
              rule && (
                <li key={rule}>
                  <TextField color="secondary">{rule}</TextField>
                </li>
              )
          )}
      </ul>
    )
  );
};

const RulesV2 = ({ rules, cancellationPolicy, cancellationFee }) => {
  return (
    (rules?.length || cancellationPolicy) && (
      <ul className="pt-2 pl-3">
        {cancellationFee !== '' && (
          <li>
            <TextField color="secondary">{getRefundTitle({ cancellationFee, cancellationPolicy })}</TextField>
          </li>
        )}
        {rules?.length > 0 &&
          rules.map(
            (rule) =>
              rule && (
                <li key={rule}>
                  <TextField color="secondary">{rule}</TextField>
                </li>
              )
          )}
      </ul>
    )
  );
};

export const RatePlanV2 = ({ ratePlan, withRadio, selectedPlan, onChange }) => {
  const {
    contentConfiguration: { colorTheme },
  } = useContext(WebsiteSettingsContext);
  const { ratePlanItem, radioButton, price } = useStyles(colorTheme);
  const [show, setShow] = useState(false);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setShow((prev) => !prev);
  }, []);

  if (!ratePlan) return null;

  const {
    name,
    mealPlans,
    money: { subTotalPrice, currency },
    cancellationFee,
    cancellationPolicy,
  } = ratePlan;
  const rules = getRules({ mealPlans });
  const isStandardPlanTitleHidden = getIsStandardPlanTitleHidden({ ratePlan });
  const collapseLabel = show ? t('Less details') : t('View details');

  const value = JSON.stringify(ratePlan);
  return (
    <>
      {withRadio ? (
        <Col data-qa="rate-plan-card" className={ratePlanItem}>
          <Radio
            className={cn(radioButton, 'radio')}
            value={value}
            id={name}
            onChange={onChange}
            checked={selectedPlan === value}
          >
            <Row justify="between" fullWidth>
              <TextField>{name}</TextField>
              <TextField variant="h5" className={price}>
                <PriceConverter amount={subTotalPrice} currency={currency} />
              </TextField>
            </Row>
            {show && (
              <RulesV2 rules={rules} cancellationFee={cancellationFee} cancellationPolicy={cancellationPolicy} />
            )}
            <TextField color="link" variant="h5" onClick={handleClick}>
              {collapseLabel}
            </TextField>
          </Radio>
        </Col>
      ) : (
        <Col>
          {isStandardPlanTitleHidden ? null : (
            <Row justify="between" fullWidth>
              <TextField>{name}</TextField>
              <TextField variant="h5" className={price}>
                <PriceConverter amount={subTotalPrice} currency={currency} />
              </TextField>
            </Row>
          )}
          <RulesV2 rules={rules} cancellationFee={cancellationFee} cancellationPolicy={cancellationPolicy} />
        </Col>
      )}
    </>
  );
};

const RatePlan = ({ ratePlan, withRadio, selectedPlan, onChange }) => {
  const {
    contentConfiguration: { colorTheme },
  } = useContext(WebsiteSettingsContext);
  const { ratePlanItem, radioButton, ratePlanName, price } = useStyles(colorTheme);

  if (!ratePlan) return null;

  const {
    name,
    _id: value,
    mealPlans,
    money: { subTotalPrice, currency },
    cancellationFee,
    cancellationPolicy,
  } = ratePlan;
  const rules = getRules({ mealPlans });
  const isStandardPlanTitleHidden = getIsStandardPlanTitleHidden({ ratePlan });

  return (
    <>
      {withRadio ? (
        <Col data-qa="rate-plan-card" className={ratePlanItem} data-chosen={selectedPlan === value}>
          <Radio
            className={cn(radioButton, 'radio')}
            value={value}
            id={name}
            onChange={onChange}
            checked={selectedPlan === value}
          >
            {name}
            <Rules rules={rules} cancellationFee={cancellationFee} cancellationPolicy={cancellationPolicy} />
          </Radio>
          <TextField variant="h5" bold className={price}>
            <PriceConverter amount={subTotalPrice} currency={currency} />
          </TextField>
        </Col>
      ) : (
        <Col>
          {isStandardPlanTitleHidden ? null : <TextField className={ratePlanName}>{name}</TextField>}
          <Rules rules={rules} cancellationFee={cancellationFee} cancellationPolicy={cancellationPolicy} />
        </Col>
      )}
    </>
  );
};

export default RatePlan;
