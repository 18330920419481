import { useHistory, useLocation } from 'react-router-dom';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import FullPageLoader from 'components/FullPageLoader';
import useSearchValues from 'hooks/useSearchValues';
import useGetListingById from 'hooks/useGetListingById';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';

import CheckOutInquiry from './CheckOutInquiry';


const CheckOutInquiryContainer = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [isGroupReservationsFTLoading, isGroupReservationsFTEnabled] = useFeatureToggle(GROUP_RESERVATIONS)

  const { listingId } = useSearchValues();
  const { property, isGettingListing } = useGetListingById({ id: listingId });
  if (isGroupReservationsFTEnabled) {
    if (!state?.quoteData?.quote?.length || !state?.ratePlan?._id) {
      history.push('/properties');
    }
  } else if (!state?.quoteId || !state?.ratePlanId) {
    history.push('/properties');
  }
  if (isGettingListing || isGroupReservationsFTLoading) {
    return <FullPageLoader />;
  }

  return (
    <CheckOutInquiry property={property} />
  )
}

export default CheckOutInquiryContainer;