import { useRef, useState } from 'react';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

import { countBy, keys, partition } from 'lodash';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { BATH_SPACE_ENABLED } from 'constants/featureToggleNames';
import { BedArrangementItem } from './BedArrangementItem';

const useStyles = createStyles(({ breakpoints: { create }, spacer }) => ({
  root: {
    display: 'flex',
    width: 'auto',
    paddingTop: spacer(3),
    flexDirection: 'column',
  },
  bedArrangementContainer: {
    maxHeight: 300,
    overflow: 'hidden',
    flexWrap: 'wrap',
    '&.isOpen': {
      height: '100%',
      maxHeight: 'inherit !important',
      overflow: 'visible',
    },
    [create('xs')]: {
      paddingLeft: spacer(4),
    },
    [create('sm')]: {
      paddingLeft: 0,
    },
  },
  containerGradient: {
    height: spacer(28),
    marginTop: -spacer(28),
    background: 'linear-gradient(to bottom, rgba(250,250,250, 0) 0%, rgba(250,250,250,1) 100%)',
    filter:
      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 )",
    '&.isOpen': {
      display: 'none',
    },
  },
}));

const MAX_SCROLL_HEIGHT = 250;

export const BedArrangement = ({ bedrooms }) => {
  const [, isBathSpaceEnabled] = useFeatureToggle(BATH_SPACE_ENABLED);
  const ref = useRef();
  const [showMore, setShowMore] = useState(false);
  const showMoreText = showMore ? t('Show less') : t('Show more');
  const { root, bedArrangementContainer, containerGradient } = useStyles();
  const toggleShowMore = () => setShowMore(!showMore);
  const elementScrollHeight = ref?.current?.firstElementChild?.scrollHeight || 0;
  const displayShowMoreButton = elementScrollHeight > MAX_SCROLL_HEIGHT;
  const [bedroomsArr, bathroomsArr] = partition(bedrooms, { type: 'BEDROOM' });
  const bathroomsArrFiltered = bathroomsArr.filter((b) => b.type === 'FULL_BATHROOM' || b.type === 'HALF_BATHROOM');
  const bathroomsArrCountBy = countBy(bathroomsArrFiltered, 'type');

  return (
    <Col spacing={4}>
      <div ref={ref} className={root}>
        <Col>
          {isBathSpaceEnabled && (
            <TextField bold className="mb-2">
              {t('{qty} {qty, plural, one {Bedroom} other {Bedrooms}}', { qty: bedroomsArr.length })}
            </TextField>
          )}
          <Row className={cn(bedArrangementContainer, { isOpen: showMore })}>
            {bedroomsArr?.map((bedroom) => {
              const { type, beds, name, roomNumber, _id } = bedroom;
              return (
                <BedArrangementItem _id={_id} key={_id} type={type} beds={beds} name={name} roomNumber={roomNumber} />
              );
            })}
          </Row>
        </Col>
        <div className={cn(displayShowMoreButton && containerGradient, { isOpen: showMore })} />
        {displayShowMoreButton && (
          <TextField className="clickable" color="link" onClick={toggleShowMore}>
            {showMoreText}
          </TextField>
        )}
      </div>
      {isBathSpaceEnabled && bathroomsArrFiltered.length ? (
        <Col>
          <TextField bold className="mb-2">
            {t('{qty} {qty, plural, one {Bathroom} other {Bathrooms}}', { qty: bathroomsArrFiltered.length })}
          </TextField>
          <Row>
            {keys(bathroomsArrCountBy).map((type) => {
              const name = type === 'FULL_BATHROOM' ? t('Full bathrooms') : t('Half bathrooms');
              return (
                <BedArrangementItem
                  _id={type}
                  key={type}
                  type={type}
                  beds={{ [type]: bathroomsArrCountBy[type] }}
                  name={name}
                />
              );
            })}
          </Row>
        </Col>
      ) : null}
    </Col>
  );
};

export default BedArrangement;
