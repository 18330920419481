import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import useLocale from './useLocale';

function usePureSearchValues() {
  const { search } = useLocation();
  const locale = useLocale();
  const {
    checkIn,
    checkOut,
    minOccupancy,
    city,
    country,
    currency,
    minPrice,
    maxPrice,
    propertyType,
    includeAmenities,
    numberOfBedrooms,
    numberOfBathrooms,
    rooms,
  } = qs.parse(search);

  return {
    dates: { startDate: checkIn, endDate: checkOut },
    location: { city, country },
    guests: minOccupancy,
    rooms,
    currency,
    minPrice,
    maxPrice,
    propertyType,
    includeAmenities,
    numberOfBedrooms,
    numberOfBathrooms,
    lang: locale,
  };
}

export default usePureSearchValues;
