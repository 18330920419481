import { useInfiniteQuery } from 'react-query';
import qs from 'query-string';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import getListings from 'api/listings/getListings';

import { QUERY_IDS } from 'api';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';
import { generateSearchParams } from 'utils';

import { useRef } from 'react';
import { countBy } from 'lodash';
import { PRICE_FILTER_FIX } from '../constants/featureToggleNames';
import averageNightlyRate from '../utils/averageNightlyRate';
import useLocale from './useLocale';

const { GET_LISTINGS } = QUERY_IDS;

function removePropertiesOutOfPriceFilter({ data, search }) {
  const isFixFilterEnabled = window.featureToggle?.isEnabled(PRICE_FILTER_FIX);
  if (!isFixFilterEnabled) {
    return data;
  }
  const { minPrice = 0, maxPrice = 0 } = search || {};
  const chunkLength = data.results?.length || 0;
  if ((minPrice > 0 || maxPrice > 0) && chunkLength) {
    const filteredListings = data.results.filter((listing) => {
      const avgPrice = listing?.nightlyRates ? averageNightlyRate(listing.nightlyRates) : listing.prices?.basePrice;
      const isAvgPriceIsLowerThanMinPrice = minPrice > 0 && avgPrice < minPrice;
      const isAvgPriceIsHigherThanMaxPrice = maxPrice > 0 && avgPrice > maxPrice;
      const isAvgPriceIsOutOfPriceFilter = isAvgPriceIsLowerThanMinPrice || isAvgPriceIsHigherThanMaxPrice;
      return !isAvgPriceIsOutOfPriceFilter;
    });
    return {
      pagination: {
        ...data.pagination,
        totalDiff: chunkLength - filteredListings.length,
      },
      results: filteredListings,
    };
  }
  return data;
}


const getNextPageParamGroupReservation = ({ lastPage, allPages, exactSearchRef }) => {
  const nullCursorCount = countBy(allPages, (page) => !page.pagination.cursor.next);
  if (lastPage.pagination.cursor.next) {
    return {
      cursor: lastPage.pagination.cursor.next,
      ...(exactSearchRef.current === false && { exactSearch: false }),
    };
  }

  if (!lastPage.pagination.cursor.next) {
    if (nullCursorCount.true > 1) {
      return undefined;
    }
    // eslint-disable-next-line no-param-reassign
    exactSearchRef.current = false;
    return {
      exactSearch: false
    }
  }
}

const getNextPageParams = ({ allPages, lastPage }) => {
  const loaded = allPages.reduce((total, page) => page.results.length + total, 0);
  return loaded < lastPage.pagination.total ? { cursor: lastPage.pagination.cursor.next } : undefined;
}



const useGetInfiniteProperties = ({ search = {}, onSuccess }) => {
  const [, isGroupReservationEnabled] = useFeatureToggle(GROUP_RESERVATIONS);
  const locale = useLocale();
  const exactSearchRef = useRef();
  const localeForSearch = locale === 'en' ? undefined : locale;

  const searchWithLocale = {
    ...search,
    lang: localeForSearch,
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isSuccess, isError } = useInfiniteQuery(
    [GET_LISTINGS, searchWithLocale],
    ({ pageParam }) => {
      const searchParamsString = generateSearchParams(searchWithLocale, new URLSearchParams());
      const pureSearchParams = qs.parse(searchParamsString);
      const pureSearchParamsWithLang = {
        ...pureSearchParams,
        lang: localeForSearch,
      };
      return getListings({ pageParam, search: pureSearchParamsWithLang });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetch: false,
      getNextPageParam: (lastPage, allPages) => {
        if (isGroupReservationEnabled) {
          return getNextPageParamGroupReservation({ lastPage, allPages, exactSearchRef });
        }
        return getNextPageParams({ allPages, lastPage });
      },
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      select: ({ pages }) => {
        return pages?.reduce(
          (acc, curr) => {
            const chunk = removePropertiesOutOfPriceFilter({ data: curr, search });
            const totalDiff = acc.totalDiff + (chunk?.pagination?.totalDiff || 0);
            return {
              properties: [...acc.properties, ...chunk.results],
              markers: [
                ...acc.markers,
                ...curr.results.reduce((accumulator, property) => {
                  const { address } = property;

                  if (!address?.lat || !address?.lng) {
                    return accumulator;
                  }

                  const foundMarkersIndex = accumulator.findIndex(
                    ({ position }) => address.lat === position.lat && address.lng === position.lng
                  );

                  if (foundMarkersIndex === -1) {
                    accumulator.push({
                      position: { lng: address.lng, lat: address.lat },
                      properties: [property],
                    });

                    return accumulator;
                  }

                  accumulator[foundMarkersIndex].properties.push(property);

                  return accumulator;
                }, []),
              ],
              total: curr.pagination.total - totalDiff,
              totalDiff,
              nullCursorCount: acc.nullCursorCount + (curr.pagination.cursor.next ? 0 : 1),
            };
          },
          {
            properties: [],
            markers: [],
            total: 0,
            totalDiff: 0,
            nullCursorCount: 0,
          }
        );
      },
    }
  );

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isSuccess,
    isError,
  };
};

export default useGetInfiniteProperties;
