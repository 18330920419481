import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import RadioGroup from '@guestyci/foundation/RadioGroup';

import RatePlan from 'components/RatePlan';
import t from '@guestyci/localize/t.macro/t.macro';
import { RatePlanV2 } from 'components/RatePlan/RatePlan';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratePlanItem: {
    border: '1px solid #D4D8E1',
    margin: '5px 0px',
  },
  radioButton: {
    '& div[class*="checked"]': {
      borderColor: '#1BBCEF',
    },
    '& .radio-inner-content': {
      backgroundColor: '#1BBCEF',
    },
  },
}));

export const RatePlanRadioGroupV2 = ({ handleChange, ratePlans, selectedPlan }) => {
  const { root } = useStyles();
  if (!ratePlans) return null;

  const selectRatePlans = ratePlans.length > 1;

  return (
    <div className={root}>
      {selectRatePlans && <TextField variant="h4">{t('Select rate plan')}</TextField>}
      <div className="mb-4 mt-4">
        <RadioGroup onChange={handleChange} value={selectedPlan}>
          {ratePlans?.map(({ ratePlan }) => (
            <RatePlanV2
              withRadio={selectRatePlans}
              key={ratePlan._id}
              ratePlan={ratePlan}
              selectedPlan={selectedPlan}
              onChange={handleChange}
            />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

const RatePlanRadioGroup = ({ handleChange, ratePlans, selectedPlan }) => {
  const { root } = useStyles();
  if (!ratePlans) return null;

  return (
    <div className={root}>
      <TextField bold>{t('Select preferred package')}</TextField>
      <RadioGroup onChange={handleChange} value={selectedPlan}>
        {ratePlans?.map(({ ratePlan }) => (
          <RatePlan
            withRadio
            key={ratePlan._id}
            ratePlan={ratePlan}
            selectedPlan={selectedPlan}
            onChange={handleChange}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default RatePlanRadioGroup;
