import TextField from '@guestyci/foundation/TextField/TextField';
import { Col, Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro';

import { RatePlanRadioGroupV2 } from 'components/RatePlanRadioGroup';
import Rooms from './Rooms';

const SearchResults = ({ quoteData, handlePlanChange, selectedRatePlan, accommodates }) => {
  if (!quoteData?.length) return null;
  const sectionLabel = t('Your selection');

  const roomsCount = quoteData.length;

  return (
    <Col>
      <Row align="center" justify="between" className="mb-4">
        <TextField variant="h3">{sectionLabel}</TextField>
      </Row>
      <Rooms roomsCount={roomsCount} guestsCount={accommodates} />
      <RatePlanRadioGroupV2
        handleChange={handlePlanChange}
        ratePlans={quoteData[0]?.rates?.ratePlans}
        selectedPlan={selectedRatePlan}
      />
    </Col>
  );
};

export default SearchResults;
