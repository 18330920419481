import { useQuery } from 'react-query';

import { QUERY_IDS } from 'api';
import getGroupQuote from 'api/quote/getGroupQuote';

const { GET_GROUP_QUOTE } = QUERY_IDS;

const useGetGroupQuote = ({ quoteIds, options }) =>
  useQuery([GET_GROUP_QUOTE, quoteIds], () => getGroupQuote({ quoteId: quoteIds }), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...options,
  });

export default useGetGroupQuote;
