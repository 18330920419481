/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, useField } from 'react-final-form';

import t from '@guestyci/localize/t.macro';
import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import Icon from '@guestyci/foundation/Icon';

import { ReactComponent as CreditCardIcon } from 'assets/icons/creditCard.svg';
import { ReactComponent as BankTransferIcon } from 'assets/icons/bankTransfer.svg';

const CARD = 'card';
const BANK_TRANSFER = 'bankTransfer';

const OptionWrapper = ({ title, icon = null }) => {
  return (
    <Col spacing={1}>
      {icon && <Icon svg={icon} width={20} height={16} />}
      <TextField variant="h5">{title}</TextField>
    </Col>
  );
};

const useRadioOptionStyles = createStyles((theme) => ({
  root: {
    background: theme.palette.background.white,
    padding: 10,
    borderRadius: 8,
    cursor: 'pointer',
    border: '1px solid transparent',
    userSelect: 'none',
    width: 145,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    boxShadow: "0px 1px 5px 0px rgba(144, 144, 144, 0.12), 0px 2px 2px 0px rgba(144, 144, 144, 0.14), 0px 1px 2px -2px rgba(144, 144, 144, 0.20)",
    '& input': {
      display: 'none',
    },
    '&:has(input:checked)': {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.hover,
      background: '#F5F9FF',
      '& svg path': {
        fill: theme.palette.primary.hover,
      },
    },
  },
}));

const RadioOption = ({ name, value, children }) => {
  const { root } = useRadioOptionStyles();
  return (
    <label className={root}>
      <Field name={name} component="input" type="radio" value={value} />
      {children}
    </label>
  );
};

const PaymentMethodSwitch = ({
  creditCardForm,
  BankTransferInformation,
  isCreditCardEnabled,
  isBankTransferEnabled,
}) => {
  const paymentTypeField = useField('paymentType');
  const paymentTypeValue = paymentTypeField.input.value;
  return (
    <Col>
      <TextField variant="h3" bold className="mb-5 mt-6">{t('Payment method')}</TextField>
      <Row spacing={2}>
        {isCreditCardEnabled && (
          <RadioOption name="paymentType" value="card">
            <OptionWrapper title={t('Credit Card')} icon={CreditCardIcon} />
          </RadioOption>
        )}
        {isBankTransferEnabled && (
          <RadioOption name="paymentType" value="bankTransfer">
            <OptionWrapper title={t('Bank Transfer')} icon={BankTransferIcon} />
          </RadioOption>
        )}
      </Row>
      <div>
        {paymentTypeValue === CARD && creditCardForm}
        {paymentTypeValue === BANK_TRANSFER && BankTransferInformation}
      </div>
    </Col>
  );
};

export default PaymentMethodSwitch;
