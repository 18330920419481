import { useContext } from 'react';

import { GuestyPayWidget } from '@guestyci/guesty-pay-widget';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import PaymentMethodSwitch from 'components/PaymentMethodSwitch';
import BankTransferInformation from 'components/BankTransferInformation';
import { BANK_TRANSFER } from 'constants/featureToggleNames';

const GuestyPayInstantForm = ({ providerId, currency, hostPayout }) => {
  const {
    paymentTypes: {
      creditCard: creditCardPaymentTypeEnabled = true,
      bankTransfer: bankTransferPaymentTypeEnabled = false,
    } = {},
  } = useContext(WebsiteSettingsContext);
  const [, isBankTransferEnabled] = useFeatureToggle(BANK_TRANSFER);

  if (isBankTransferEnabled && (creditCardPaymentTypeEnabled || bankTransferPaymentTypeEnabled)) {
    return (
      <PaymentMethodSwitch
        isCreditCardEnabled={creditCardPaymentTypeEnabled}
        isBankTransferEnabled={bankTransferPaymentTypeEnabled}
        creditCardForm={<GuestyPayWidget providerId={providerId} currency={currency} amount={hostPayout} />}
        BankTransferInformation={<BankTransferInformation />}
      />
    );
  }

  return <GuestyPayWidget providerId={providerId} currency={currency} amount={hostPayout} />;
};

export default GuestyPayInstantForm;
