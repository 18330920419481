import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';
import Icon from '@guestyci/foundation/Icon';

import useSearchValues from 'hooks/useSearchValues';
import useGetRoomsLabel from 'hooks/useGetRoomsLabel';

import { ReactComponent as Arrow } from 'assets/icons/arrow_down.svg';
import { ReactComponent as IconGuests } from 'assets/icons/guests.svg';

import PriceConverter from 'components/PriceConverter';
import RatePlan from 'components/RatePlan';
import { Fees } from 'components/Prices';

const useStyles = createStyles(() => ({
  root: {
    borderRadius: 4,
    border: '1px solid  #D4D8E1',
    padding: [25, 15],
    margin: [10, 0],
  },
  collapseWrapper: {
    display: ({ collapsed }) => (collapsed ? 'none' : 'block'),
    '& button *': {
      color: '#242428',
    },
  },
  arrow: {
    transform: ({ collapsed }) => (collapsed ? 'rotate(0deg)' : 'rotate(180deg)'),
  },
  titleWrapper: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  propertyName: {
    maxWidth: 230,
  },
}));

const GuestsCount = ({ count }) => {
  const guestsPluralLabel = t(
    `
  {
    count,
    plural,
    =0 {0 guests}
    one {# Guest}
    other {# Guests}
  }
`,
    { count }
  );
  return (
    <Row align="center" spacing={1}>
      <Icon svg={IconGuests} width={16} height={16} />
      <TextField>{guestsPluralLabel}</TextField>
    </Row>
  );
};

const RoomsInformation = () => {
  const { isRoomLabel } = useGetRoomsLabel();
  const [collapsed, setCollapsed] = useState(true);
  const { root, titleWrapper, collapseWrapper, arrow, propertyName } = useStyles({ collapsed });
  const { rooms } = useSearchValues();
  const { state } = useLocation();

  const totalLabel = isRoomLabel ? t('Total per room') : t('Total per unit');

  const handleClick = () => {
    setCollapsed(prev => !prev);
  };

  const property = state?.property;
  const accommodates = property?.accommodates;
  const ratePlan = state?.ratePlan;
  const money = state?.ratePlan?.money;
  const invoiceItems = money?.invoiceItems;
  const propertiesAndRooms = `${property?.title} X${rooms}`;
  const fareAccomodation = money?.fareAccommodation;
  const subTotalPrice = money?.subTotalPrice;
  const currency = money?.currency;
  return (
    <div className={root}>
      <Row align="center" justify="between" className={titleWrapper} onClick={handleClick}>
        <Row spacing={3} align="center">
          <Icon svg={Arrow} className={arrow} />
          <TextField variant="h4" bold className={propertyName}>
            {propertiesAndRooms}
          </TextField>
          <GuestsCount count={accommodates} />
        </Row>
        <TextField>
          <PriceConverter amount={fareAccomodation} currency={currency} />
        </TextField>
      </Row>
      <Col className={collapseWrapper}>
        <RatePlan ratePlan={ratePlan} />
        <div className="pl-2">
          <Fees invoiceItems={invoiceItems} />
        </div>
        <Row className="pl-2" fullWidth justify="between">
          <TextField>{totalLabel}</TextField>
          <PriceConverter amount={subTotalPrice} currency={currency} />
        </Row>
      </Col>
    </div>
  );
};

export default RoomsInformation;
