import { useLocation } from 'react-router-dom';

import useSearchValues from './useSearchValues';
import useGoogleTagManager from './useGoogleTagManager';
import useFacebookPixel from './useFacebookPixel';
import useDio from './useDio';

const useTrackCreateReservationSuccess = ({ property, provider = null, type }) => {
  const { dioTrack } = useDio();
  const { gtagEcommercePurchase } = useGoogleTagManager();
  const { fbtrack } = useFacebookPixel();
  const { minOccupancy, pointofsale } = useSearchValues();
  const { state: { ratePlan: { money } = {} } = {} } = useLocation();

  const reservationTotalAmount = money?.hostPayout;

  const trackSuccess = ({ response, dioAdditionalData }) => {
    const { _id } = response;
    dioTrack('booking_create_success', 'side-effect', {
      reservationId: _id,
      type,
      ...(provider && { provider }),
      ...dioAdditionalData,
      listings: [
        {
          guests: minOccupancy,
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
        },
      ],
    });
    gtagEcommercePurchase({
      reservationId: _id,
      totalPrice: reservationTotalAmount,
      listingId: property._id,
      listingName: property.title,
      currency: property.prices.currency,
      checkInDate: response.checkInDateLocalized,
      checkOutDate: response.checkOutDateLocalized,
      numberOfGuests: response.guestsCount,
      pointOfSale: pointofsale,
    });
    fbtrack('Purchase', {
      listing_id: [property._id],
      content_name: property.title,
      content_type: property.propertyType,
      currency: property.prices.currency,
      reservationTotalAmount,
      num_guests: response.guestsCount,
      check_in_date: response.checkInDateLocalized,
      check_out_date: response.checkOutDateLocalized,
      reservation_id: _id,
      point_of_sale: pointofsale,
    });
  };

  return {
    trackSuccess,
  };
};

export default useTrackCreateReservationSuccess;
