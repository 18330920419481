import t from '@guestyci/localize/t.macro';

export const languagesList = [
  'de-DE',
  'el-GR',
  'en-US',
  'es-ES',
  'fr-FR',
  'id-ID',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'pl-PL',
  'pt-PT',
  'ro-RO',
  'zh-cmn',
  'nl-NL',
];

export const shortLanguageList = ['de', 'el', 'en', 'es', 'fr', 'id', 'it', 'ja', 'ko', 'pl', 'pt', 'ro', 'zh', 'nl'];

export const fullCodesToShort = {
  'de-DE': 'de',
  'el-GR': 'el',
  'en-US': 'en',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'id-ID': 'id',
  'it-IT': 'it',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'pl-PL': 'pl',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'zh-cmn': 'zh',
  'nl-NL': 'nl',
};

export const languagesToLabel = () => [
  {
    label: t('English'),
    value: 'en',
  },
  {
    label: t('German'),
    value: 'de',
  },
  {
    label: t('Spanish (Spain)'),
    value: 'es',
  },
  {
    label: t('French'),
    value: 'fr',
  },
  {
    label: t('Italian'),
    value: 'it',
  },
  {
    label: t('Polish'),
    value: 'pl',
  },
  {
    label: t('Portuguese'),
    value: 'pt',
  },
  {
    label: t('Chinese'),
    value: 'zh',
  },
  {
    label: t('Romanian'),
    value: 'ro',
  },
  {
    label: t('Greek'),
    value: 'el',
  },
  {
    label: t('Japanese'),
    value: 'ja',
  },
  {
    label: t('Korean'),
    value: 'ko',
  },
  {
    label: t('Indonesian'),
    value: 'id',
  },
  {
    label: t('Dutch'),
    value: 'nl',
  },
];

export const countryCodeToLabel = (value) => {
  const language = languagesToLabel().find(({ value: languageValue }) => languageValue === value);
  return language?.label;
};

export const mapLanguagesToShortCodes = (languages) => {
  return languages?.map((language) => {
    return {
      ...language,
      language: fullCodesToShort[language.language],
    };
  });
};
