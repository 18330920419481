import { api } from 'api';

const URL = '/reservations/group/quotes';

const getGroupQuote = async ({ quoteId }) => {
  const { data } = await api.get(URL, {
    params: {
      quoteIds: quoteId,
    },
  });

  return data;
}

export default getGroupQuote;