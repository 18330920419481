import TextField from '@guestyci/foundation/TextField';
import Icon from '@guestyci/foundation/Icon';
import { Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { getPriceStr } from 'utils';
import { useContext } from 'react';
import { CurrencyContext } from 'context/CurrencyContext';

export const PriceInfoV2 = ({ hostPayout, currency }) => {
  const { selectedCurrency } = useContext(CurrencyContext);
  const label = t(
    "You'll pay <b>{price}</b>. The price displayed is an estimation based on the current exchange rate. The amount you'll pay may change based on the exchange rate at the moment of the final payment.",
    {
      price: getPriceStr(hostPayout, currency),
      b: (chunks) => <strong>{chunks}</strong>,
    }
  );

  return currency !== selectedCurrency ? (
    <Row className="pt-2">
      <Icon svg={InfoIcon} />
      <TextField color="default" className="pl-2">
        {label}
      </TextField>
    </Row>
  ) : null;

}

const PriceInfo = ({ ratePlan }) => {
  const money = ratePlan?.money || null;
  const { selectedCurrency } = useContext(CurrencyContext);
  const label = t(
    "You'll pay <b>{price}</b>. The price displayed is an estimation based on the current exchange rate. The amount you'll pay may change based on the exchange rate at the moment of the final payment.",
    {
      price: getPriceStr(money?.hostPayout, money?.currency),
      b: (chunks) => <strong>{chunks}</strong>,
    }
  );

  return money && money.currency !== selectedCurrency ? (
    <Row className="pt-2">
      <Icon svg={InfoIcon} />
      <TextField color="default" className="pl-2">
        {label}
      </TextField>
    </Row>
  ) : null;
};

export default PriceInfo;
