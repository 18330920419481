import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import createDecorator from 'final-form-calculate';
import { Link } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';

import { Form as FinalForm, Field } from 'react-final-form';
import { FormProvider } from '@guestyci/foundation/enums';
import Form from '@guestyci/foundation/Form';
import Divider from '@guestyci/foundation/Divider';
import FormField from '@guestyci/foundation/FormField';
import FormattedMessage from '@guestyci/localize/FormattedMessage';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import Checkbox from '@guestyci/foundation/Checkbox';

import Breadcrumbs from 'components/Breadcrumbs';
import Icon from 'components/Icon';
import ReservationErrorDialog from 'components/ErrorDialog/ReservationErrorDialog';

import CheckOutSummaryGroupReservation from 'components/CheckOutSummary/CheckOutSummaryGroupReservation';
import { StripeInstantForm } from 'components/InstantForm';
import ErrorDialog from 'components/ErrorDialog';
import ZeroAmountNotificationDialog, { useZeroAmountNotificationDialog } from 'components/ZeroAmountNotificationDialog';

import useSearchValues from 'hooks/useSearchValues';
import useSubmitInstantStripe from 'hooks/useSubmitInstantStripe';

import useGoogleTagManager from 'hooks/useGoogleTagManager';
import usePaymentTypeInitialValue from 'hooks/usePaymentTypeInitialValue';
import useFacebookPixel from 'hooks/useFacebookPixel';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

import { CREDIT_CARD_USAGE, GROUP_RESERVATIONS } from 'constants/featureToggleNames';
import CheckOutSummary from 'components/CheckOutSummary';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: '#fff',
  },
  contentWrapper: {
    background: '#FAFAFA',
    width: '100%',
    maxWidth: 1500,
    borderRadius: 20,
    display: 'flex',
    [create('xs')]: {
      flexWrap: 'wrap',
      padding: '10px 10px',
      justifyContent: 'center',
    },
    [create('md')]: {
      padding: '30px 30px 80px',
    },
    [create('lg')]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },
  title: {
    marginBottom: 30,
    marginTop: 30,
  },
  pci: {
    marginBottom: 30,
  },
  detailsRoot: {
    [create('lg')]: {
      maxWidth: 450,
    },
    [create('xl')]: {
      width: '100%',
      maxWidth: 600,
    },
  },
  agreements: {
    [create('xs')]: {
      width: 250,
    },
    [create('md')]: {
      width: '100%',
    },
  },
  privacyWrapper: {
    marginTop: 30,
    marginBottom: 20,
  },
  discountsWrapper: {},
  formContainer: {
    [create('xs')]: {
      width: '100%',
    },
    [create('xl')]: {
      width: 560,
    },
  },
  reuseCheckbox: {
    marginLeft: -10,
    marginBottom: 30,
  },
}));

const required = (value) => (value ? undefined : 'Required');

const Summary = ({ submitRef, isLoading, isFormInvalid, onChangePriceAmount }) => {
  const [, isFtEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  if (isFtEnabled) {
    return (
      <CheckOutSummaryGroupReservation
        submit={submitRef}
        isLoading={isLoading}
        isFormInvalid={isFormInvalid}
        onChangePriceAmount={onChangePriceAmount}
      />
    );
  }

  return (
    <CheckOutSummary
      onChangePriceAmount={onChangePriceAmount}
      submit={submitRef}
      isLoading={isLoading}
      isFormInvalid={isFormInvalid}
    />
  );
};

const Checkout = ({ property }) => {
  const { isFeatureEnabled: isZeroAmountNotificationEnabled } = useZeroAmountNotificationDialog();
  const [isFeaturesLoading, featureToggles] = useFeatureToggle([CREDIT_CARD_USAGE]);
  const isCreditCardReusageAvailable = featureToggles[CREDIT_CARD_USAGE];
  const paymentTypeInitialValue = usePaymentTypeInitialValue();
  const {
    submitHandler,
    setIsStripeError,
    isRequestingReservation,
    isStripeLoading,
    isStripeError,
    stripeErrorMessage,
    setReservationError,
    reservationError,
    zeroAmountNotificationDialogIsOpen,
  } = useSubmitInstantStripe({ property });
  const { getPathWithLocale } = useGetPathToNavigate();
  const { gtagEcommerceBeginCheckout } = useGoogleTagManager();

  const {
    companyInfo: { name },
    userLocation,
  } = useContext(WebsiteSettingsContext);
  const { minOccupancy, pointofsale, checkInDateLocalized, checkOutDateLocalized } = useSearchValues();
  const submitRef = useRef(null);
  const [isInvalid, setIsInvalid] = useState(true);
  const {
    root,
    contentWrapper,
    title,
    pci,
    formRoot,
    detailsRoot,
    agreements,
    privacyWrapper,
    discountsWrapper,
    formContainer,
    reuseCheckbox,
  } = useStyles();

  const { fbtrack } = useFacebookPixel();
  const [reservationTotalAmount, setReservationTotalAmount] = useState(null);

  const handleChangeReservationTotalAmmount = useCallback(
    (totalAmount) => {
      setReservationTotalAmount(totalAmount);
    },
    [setReservationTotalAmount]
  );

  const cardHolderNameDecorator = useMemo(
    () =>
      createDecorator({
        field: 'cardHolderNameAsGuest',
        updates: (value, _, allValues) =>
          value
            ? {
              ...allValues,
              cardHolderFirstName: undefined,
              cardHolderLastName: undefined,
            }
            : allValues,
      }),
    []
  );

  const billingAddressDecorator = useMemo(
    () =>
      createDecorator({
        field: 'billingAddress',
        updates: (value, _, allValues) =>
          value
            ? {
              ...allValues,
              billingAddressStreet: undefined,
              billingAddressCity: undefined,
              billingAddressState: undefined,
              billingAddressZipCode: undefined,
              billingAddressCountry: undefined,
            }
            : allValues,
      }),
    []
  );

  useEffect(() => {
    if (!reservationTotalAmount || !property) return;

    gtagEcommerceBeginCheckout({
      listingId: property._id,
      listingName: property.title,
      totalPrice: reservationTotalAmount,
      currency: property.prices.currency,
      checkInDate: checkInDateLocalized || null,
      checkOutDate: checkOutDateLocalized || null,
      numberOfGuests: minOccupancy || null,
      pointOfSale: pointofsale,
    });
    fbtrack('InitiateCheckout', {
      listing_id: [property._id],
      content_name: property.title,
      content_type: property.propertyType,
      currency: property.prices.currency,
      value: reservationTotalAmount,
      num_guests: minOccupancy,
      check_in_date: checkInDateLocalized,
      check_out_date: checkOutDateLocalized,
      point_of_sale: pointofsale,
    });
  }, [
    reservationTotalAmount,
    property,
    checkInDateLocalized,
    minOccupancy,
    checkOutDateLocalized,
    pointofsale,
    gtagEcommerceBeginCheckout,
    fbtrack,
  ]);

  return (
    <div className={root}>
      <div className={contentWrapper}>
        <div className={detailsRoot}>
          <Breadcrumbs />
          <TextField variant="h1" className={title}>
            {t('Fill in your details')}
          </TextField>
          <TextField className={pci}>
            <Icon icon="lock" size="12" className="mr-2" />
            {t('PCI compliant secure payment')}
          </TextField>
          <FinalForm
            onSubmit={submitHandler}
            decorators={[cardHolderNameDecorator, billingAddressDecorator]}
            mutators={{ ...arrayMutators }}
            initialValues={{
              paymentType: paymentTypeInitialValue,
              phone: userLocation?.countryPhoneCode,
            }}
            render={({ handleSubmit, invalid }) => {
              submitRef.current = handleSubmit;
              setIsInvalid(invalid);
              return (
                <Form
                  title="checkout"
                  onSubmit={handleSubmit}
                  provider={FormProvider.Final}
                  fieldInstance={Field}
                  className={formRoot}
                >
                  <div className={formContainer}>
                    <StripeInstantForm />
                    {isCreditCardReusageAvailable && (
                      <FormField name="reusePaymentMethod" type="checkbox" className={reuseCheckbox}>
                        <Checkbox>
                          <TextField color="default">
                            {t('Save payment information to my account for future reservations')}
                          </TextField>
                        </Checkbox>
                      </FormField>
                    )}
                  </div>
                  <Divider />
                  <FormField className={privacyWrapper} name="privacyPolicy" validate={[required]} type="checkbox">
                    <Checkbox>
                      <FormattedMessage
                        defaultMessage=" I have read and accept the <link>Privacy Policy</link>"
                        values={{
                          link: (chunks) => <Link to={getPathWithLocale('/privacy-policy')}>{chunks}</Link>,
                        }}
                      />
                      {' | '}
                      <FormattedMessage
                        defaultMessage="<link>{name}</link>"
                        values={{
                          name,
                          link: (chunks) => (
                            <Link to={getPathWithLocale('/terms')}>
                              {chunks} 
                              {' '}
                              {t('Terms and Conditions')}
                            </Link>
                          ),
                        }}
                      />
                    </Checkbox>
                  </FormField>
                  <FormField className={discountsWrapper} name="discounts" type="checkbox">
                    <Checkbox>
                      <TextField className={agreements}>
                        {t('I am interested in receiving discounts, promotions and news about {name}', { name })}
                      </TextField>
                    </Checkbox>
                  </FormField>
                </Form>
              );
            }}
          />
        </div>
        <Summary
          submitRef={submitRef}
          isLoading={isStripeLoading || isFeaturesLoading || isRequestingReservation}
          isFormInvalid={isInvalid}
          onChangePriceAmount={handleChangeReservationTotalAmmount}
        />
      </div>
      <ErrorDialog isOpen={isStripeError} message={stripeErrorMessage} handleClose={() => setIsStripeError(false)} />
      <ReservationErrorDialog
        error={reservationError}
        open={!!reservationError}
        handleClose={() => setReservationError(false)}
      />
      {isZeroAmountNotificationEnabled && <ZeroAmountNotificationDialog open={zeroAmountNotificationDialogIsOpen} />}
    </div>
  );
};

export default Checkout;
