import { useStripe, useElements } from '@stripe/react-stripe-js';

import useSearchValues from './useSearchValues';
import useSetupIntents from './useSetupIntents';

const useHandleStripe = () => {
  const { listingId } = useSearchValues();
  const stripe = useStripe();
  const elements = useElements();
  const { mutateAsync: createStripeSetupIntent } = useSetupIntents();

  const confirmCardSetup = async ({ values }) => {
    const {
      billingAddressCity = null,
      billingAddressCountry,
      billingAddressState = null,
      billingAddressStreet,
      billingAddressZipCode,
      cardHolderFirstName,
      cardHolderLastName,
      cardHolderNameAsGuest = false,
      firstName,
      lastName,
    } = values;
    const cardElement = elements.getElement('cardNumber');
    const cardholderName = cardHolderNameAsGuest
      ? `${cardHolderFirstName} ${cardHolderLastName}`
      : `${firstName} ${lastName}`;

    if (!stripe || !elements) {
      // TODO: handle error
      throw new Error({
        message: 'Stripe is not initialized'
      });
    }
    const intents = await createStripeSetupIntent(listingId);
    const response = await stripe.confirmCardSetup(intents.client_secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardholderName,
          address: {
            line1: billingAddressStreet,
            postal_code: billingAddressZipCode,
            country: billingAddressCountry,
            ...(billingAddressCity && { city: billingAddressCity }),
            ...(billingAddressState && { state: billingAddressState }),
          },
        },
      },
    });
    return response;
  }

  return {
    confirmCardSetup,
  }
}

export default useHandleStripe;