import { useEffect, useMemo } from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import { useLocation, useParams } from 'react-router-dom';

import createStyles from '@guestyci/foundation/createStyles';
import Divider from '@guestyci/foundation/Divider';
import TextField from '@guestyci/foundation/TextField';
import { Row, Col } from '@guestyci/foundation/Layout';
import Spinner from '@guestyci/foundation/Spinner';
import t from '@guestyci/localize/t.macro/t.macro';

import Promotion from 'components/Promotion';
import PriceConverter from 'components/PriceConverter';
import DatesAndGuests from 'components/DatesAndGuests';
import { PriceInfoV2 } from 'components/PriceInfo';
import Image from 'components/Image/Image';
import Coupon from 'components/Coupon';

import {
  calculateFareAccommodationAdjusted,
  calculateHostPayout,
  calculateTotalFees,
  calculateTotalTaxes,
} from 'utils/money.util'

import useGetListingById from 'hooks/useGetListingById';
import useSearchValues from 'hooks/useSearchValues';
import useDio from 'hooks/useDio';
import useBookNowButtonText from 'hooks/useBookNowButtonText';
import useGetGroupQuote from 'hooks/useGetGroupQuote';


import RoomsInformation from './RoomsInformation';


const useStyles = createStyles(({ breakpoints: { create }, boxShadow }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 473,
    overflow: 'hidden',
    boxShadow: boxShadow[1],
    borderRadius: 20,
    marginTop: 10,
    background: '#fff',
    [create('xs')]: {
      marginTop: 40,
    },
  },
  contentWrapper: {
    padding: 30,
    [create('xs')]: {
      padding: 15,
    },
    [create('md')]: {
      padding: 30,
    }
  },
  image: {
    '& > img': {
      width: '100%',
      height: 240,
      objectFit: 'cover',
    },
  },
  title: {
    fontSize: 30,
    fontWeight: '600',
    [create('xs')]: {
      fontSize: 24,
    },
  },
  dates: {
    display: 'flex',
    marginBottom: 10,
    [create('xs')]: {},
  },
  dateBlock: {
    '&:first-child': {
      marginRight: 30,
    },
  },
  date: {
    fontSize: 16,
  },
  guests: {
    marginBottom: 10,
  },
  rooms: {
    marginBottom: 10,
  },
  nightsWithPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  promotion: {
    marginTop: 10,
  },
  oldSubTotal: {
    textDecoration: 'line-through',
  },
  submitButton: {
    fontSize: 18,
    marginTop: 40,
    width: '100%',
  },
}));

function getSelectedRatePlans(quote, selectedRatePlanId) {
  return quote?.map((q) => {
    const {
      rates: { ratePlans },
    } = q;
    return find(ratePlans, (rp) => rp.ratePlan._id === selectedRatePlanId);
  });
}


const CheckOutSummaryGroupReservation = ({ submit, isLoading, isFormInvalid, onChangePriceAmount }) => {
  const { root, contentWrapper, image, title, promotion, submitButton } = useStyles();
  const {
    state: {
      quoteData: { quote },
      ratePlan: { _id: selectedRatePlanId },
    },
  } = useLocation();

  const quoteIds = quote?.map(({ _id }) => _id);
  const {
    data,
    refetch,
  } = useGetGroupQuote({ quoteIds });

  const selectedRatePlans = useMemo(() => getSelectedRatePlans(data?.results, selectedRatePlanId), [data?.results, selectedRatePlanId]);

  const { money, money: { currency } = {} } = selectedRatePlans?.length ? selectedRatePlans[0]?.ratePlan : {};
  const { dioTrack } = useDio();
  const { id } = useParams();
  const { startDate, endDate, minOccupancy, rooms } = useSearchValues();
  const bookNowButtonText = useBookNowButtonText();

  const { property, isGettingProperty, isGettingPropertyError, isSuccess } = useGetListingById({ id });

  useEffect(() => {
    if (onChangePriceAmount) {
      onChangePriceAmount(money?.hostPayout);
    }
  }, [money?.hostPayout, onChangePriceAmount]);


  const fareAccommodationAdjusted = useMemo(
    () => calculateFareAccommodationAdjusted(selectedRatePlans),
    [selectedRatePlans]
  );
  const hostPayout = useMemo(() => calculateHostPayout(selectedRatePlans), [selectedRatePlans]);
  const totalFees = useMemo(() => calculateTotalFees(selectedRatePlans), [selectedRatePlans]);
  const totalTaxes = useMemo(() => calculateTotalTaxes(selectedRatePlans), [selectedRatePlans]);

  if (isGettingProperty) {
    return (
      <div className={root}>
        <Spinner />
      </div>
    );
  }

  if (isGettingPropertyError) {
    return (
      <div className={root}>
        <h1>{t('Error')}</h1>
      </div>
    );
  }


  const handleTrackBooking = () => {
    dioTrack('click_checkout', 'click', {
      listings: [
        {
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
          quantity: 1,
        },
      ],
    });
  };


  return (
    isSuccess && (
      <div className={root}>
        <div className={image}>
          <Image alt="property" src={property?.pictures[0]?.original || property.picture?.thumbnail} h={240} />
        </div>
        <div className={contentWrapper}>
          <TextField className={title}>{property?.title}</TextField>
          <DatesAndGuests startDate={startDate} endDate={endDate} guestsCount={minOccupancy} rooms={rooms} />
          <Divider />
          <RoomsInformation />
          <Promotion promotion={quote?.promotion} className={promotion} />
          <Coupon
            quoteId={quoteIds}
            actualDiscount={0}
            getUpdatedQuote={refetch}
            hostPayout={hostPayout}
            isGroupReservation
          />
          <Divider />
          <Row className="pb-2 pt-2" justify="between" align="end">
            <TextField bold>{t('Subtotal')}</TextField>
            <Col align="center">
              <TextField bold>
                <PriceConverter amount={fareAccommodationAdjusted} currency={currency} />
              </TextField>
            </Col>
          </Row>
          <Divider />
          <Row justify="between" className="mt-2 mb-2">
            <TextField>{t('Fees')}</TextField>
            <TextField>
              <PriceConverter amount={totalFees} currency={currency} />
            </TextField>
          </Row>
          <Row justify="between" className="mb-2">
            <TextField>{t('Taxes')}</TextField>
            <TextField>
              <PriceConverter amount={totalTaxes} currency={currency} />
            </TextField>
          </Row>
          <Divider />
          <Row className="pb-2 pt-2" justify="between" align="end">
            <TextField bold variant="h3">
              {t('Total')}
            </TextField>
            <Col align="center">
              <TextField data-qa="total-price" bold variant="h3">
                <PriceConverter amount={hostPayout} currency={currency} />
              </TextField>
            </Col>
          </Row>
          <PriceInfoV2 hostPayout={hostPayout} currency={currency} />
          <button
            data-qa="submit"
            type="submit"
            className={cn('btn btn-colored', submitButton)}
            disabled={isLoading || isFormInvalid}
            onClick={(event) => {
              handleTrackBooking();
              submit.current(event);
            }}
          >
            {isLoading ? <Spinner /> : bookNowButtonText}
          </button>
        </div>
      </div>
    )
  );
};

export default CheckOutSummaryGroupReservation;
