import TagManager from 'react-gtm-module';

class GTAG {
  constructor() {
    this.tagmanager = TagManager;
    this.isInitialized = false;
  }

  initialize(gtmId) {
    if (gtmId && !this.isInitialized) {
      this.tagmanager.initialize({
        gtmId,
      });
      this.isInitialized = true;
    }
  }

  static gtag(...[type, event, payload]) {
    if (!window.gtag) return;
    return window.gtag(type, event, payload);
  }

  dataLayerPush(payload) {
    return this.tagmanager.dataLayer({
      dataLayer: payload,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  gtagEvent(event, payload) {
    return GTAG.gtag('event', event, payload);
  }

  gtagEcommerceClear() {
    this.dataLayerPush({ ecommerce: null });
  }

  /**
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {number} data.totalPrice
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {number} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommerceViewItem(data) {
    this.gtagEcommerceClear();
    this.gtagEvent('view_item', {
      currency: data.currency,
      value: data.totalPrice,
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          // coupon: "SUMMER_FUN",
          // discount: 2.22,
          index: 0,
          // item_brand: "Google",
          // item_category4: "Crew",
          // item_category5: "Short sleeve",
          // item_list_id: "related_products",
          // item_list_name: "Related Products",
          // item_variant: "green",
          // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: data.totalPrice,
          quantity: 1,
        },
      ],
    });
  }

  /**
   * @param {number} data.totalPrice
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommerceBeginCheckout(data) {
    this.gtagEcommerceClear();
    this.gtagEvent('begin_checkout', {
      value: data.totalPrice,
      // tax: 0,
      // shipping: 0,
      currency: data.currency,
      // coupon: '',
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          // coupon: '',
          // discount: 0,
          index: 0,
          price: data.totalPrice, // fixme: fix for group reservations
          quantity: 1,
        },
      ],
    });
  }

  /**
   * @param {string} data.reservationId
   * @param {number} data.reservationTotalAmount
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommercePurchase(data) {
    this.gtagEcommerceClear();
    this.gtagEvent('purchase', {
      transaction_id: data.reservationId,
      value: data.totalPrice,
      // tax: 0,
      // shipping: 0,
      currency: data.currency,
      // coupon: '',
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          // coupon: '',
          // discount: 0,
          index: 0,
          price: data.totalPrice, // fixme: fix for group reservations
          quantity: 1,
        },
      ],
    });
  }
}

export default new GTAG();
