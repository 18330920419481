import { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';

import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Input from '@guestyci/foundation/Input';
import Accordion from '@guestyci/foundation/Accordion';
import DropdownMenu from '@guestyci/foundation-legacy/baseDropdown/DropdownMenu';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { getSymbolFromCurrency, prettifyEnumText } from 'utils';
import useSearchValues from 'hooks/useSearchValues';
import useIsMobile from 'hooks/useIsMobile';
import currencySymbolMap from 'constants/currencies';
import { CurrencyContext } from 'context/CurrencyContext';
import Icon from 'components/Icon';
import { getPropertyTypes, getAmenitiesTypes } from 'constants/constants';
import { PRICE_FILTER_FIX } from 'constants/featureToggleNames';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [create('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingBottom: 0,
    },
    [create('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 20,
    },
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    cursor: 'pointer',
  },
  priceInput: {
    display: 'flex',
  },
  dropdownRoot: {
    width: 'initial',
    marginRight: 23,
    cursor: 'pointer',
    [create('xs')]: {
      padding: 15,
    },
    [create('lg')]: {
      padding: 0,
    },
  },
  block: {
    display: 'flex',
    [create('xs')]: {
      borderLeft: 'none',
      padding: 0,
    },
    [create('lg')]: {
      borderLeft: '1px solid #77838D',
      padding: '0 15px',
    },
  },
  total: {
    [create('xs')]: {
      margin: 15,
    },
    [create('lg')]: {
      margin: 0,
    },
  },
  accordion: {
    paddingBottom: 0,
    '& button': {
      padding: '0 15px',
    },
  },
  clearAll: {
    cursor: 'pointer',
    [create('xs')]: {
      margin: 15,
    },
    [create('lg')]: {
      margin: 0,
    },
  },
}));

const SearchFilters = ({ totalListings, params }) => {
  const propertyTypes = getPropertyTypes();
  const amenityTypes = getAmenitiesTypes();

  const { searchSettings } = useContext(WebsiteSettingsContext);
  const {
    enableBathroomsFilter: { value: maxBathrooms = 10, active: activeBathroom = true } = {},
    enableBedroomsFilter: { value: maxBedrooms = 10, active: activeBedroom = true } = {},
    filterByAmenities: { value: amenitiesData, active: activeAmenities = true } = {},
    filterByPropertyType: { value: propertyData, active: activePropertyType = true } = {},
  } = searchSettings || {};

  const amenitiesList = amenitiesData?.length
    ? amenitiesData.reduce((acc, el) => {
        acc[el] = amenityTypes[el];
        return acc;
      }, {})
    : amenityTypes;

  const propertiesList = propertyData?.length
    ? propertyData.reduce((acc, el) => {
        acc[el] = propertyTypes[el];
        return acc;
      }, {})
    : propertyTypes;

  const isMobile = useIsMobile();
  const { root, accordion, priceWrapper, priceInput, dropdownRoot, block, total, clearAll } = useStyles();
  const { defaultCurrency, selectedCurrency } = useContext(CurrencyContext);
  const currencySymbol = getSymbolFromCurrency(selectedCurrency, currencySymbolMap);
  const searchParams = useSearchValues();
  const [open, setOpen] = useState(false);
  const [isRangeCollapsed, setIsRangeCollapsed] = useState(true);
  const [minPrice, setMinPrice] = useState(searchParams.minPrice || null);
  const [maxPrice, setMaxPrice] = useState(searchParams.maxPrice || null);
  const [propertyType, setPropertyType] = useState(searchParams.propertyType || null);
  const [amenities, setAmenities] = useState(searchParams.includeAmenities?.split(',') || []);
  const [numberOfBedrooms, setNumberOfBedrooms] = useState(searchParams.numberOfBedrooms || 0);
  const [numberOfBathrooms, setNumberOfBathrooms] = useState(searchParams.numberOfBathrooms || 0);
  const [, isPriceFilterFixEnabled] = useFeatureToggle(PRICE_FILTER_FIX);

  const showPropertiesCount = !isPriceFilterFixEnabled || !(minPrice || maxPrice);

  const minPriceText = minPrice ? currencySymbol + minPrice : '0';
  const maxPriceText = maxPrice ? currencySymbol + maxPrice : 'max';
  const allText = t('All');
  const propertyTypeText = `${t('Property Type:')} ${propertyType ? prettifyEnumText(propertyType) : allText}`;
  const amenitiesText = `${t('Amenities:')} ${amenities.length ? prettifyEnumText(amenities[0]) : allText}`;
  const numberOfBedroomsText = `${t('Number of bedrooms:')} ${numberOfBedrooms || allText}`;
  const numberOfBathroomsText = `${t('Number of bathrooms:')} ${numberOfBathrooms || allText}`;

  const handleChangePropertyType = useCallback((event) => {
    const { value } = event.target;
    setPropertyType(value);
  }, []);

  const handleChangeAmenities = useCallback((event) => {
    const { value } = event.target;
    setAmenities(value);
  }, []);

  const handleChangeNumberOfBedrooms = useCallback((event) => {
    const { value } = event.target;
    setNumberOfBedrooms(value);
  }, []);

  const handleChangeNumberOfBathrooms = useCallback((event) => {
    const { value } = event.target;
    setNumberOfBathrooms(value);
  }, []);

  const handleChangeMinPrice = useCallback((event) => {
    const { value } = event.target;
    setMinPrice(parseInt(value, 10));
  }, []);

  const handleChangeMaxPrice = useCallback((event) => {
    const { value } = event.target;
    setMaxPrice(parseInt(value, 10));
  }, []);

  const resetPriceHandler = useCallback(() => {
    setMinPrice(null);
    setMaxPrice(null);
  }, []);

  const resetPrice = useCallback(
    (e) => {
      e.stopPropagation();
      resetPriceHandler();
    },
    [resetPriceHandler]
  );

  const resetPropertyType = useCallback((e) => {
    e.stopPropagation();
    setPropertyType(null);
  }, []);

  const resetAmenities = useCallback((e) => {
    e.stopPropagation();
    setAmenities([]);
  }, []);

  const resetNumberOfBedrooms = useCallback((e) => {
    e.stopPropagation();
    setNumberOfBedrooms(0);
  }, []);

  const resetNumberOfBathrooms = useCallback((e) => {
    e.stopPropagation();
    setNumberOfBathrooms(0);
  }, []);

  const clearAllHandler = useCallback(() => {
    setAmenities([]);
    setMaxPrice(null);
    setMinPrice(null);
    setNumberOfBathrooms(0);
    setNumberOfBedrooms(0);
    setPropertyType(null);
  }, []);

  const handleClosePriceFilter = useCallback(() => {
    if (minPrice && maxPrice && minPrice > maxPrice) {
      return;
    }
    setIsRangeCollapsed(true);
  }, [minPrice, maxPrice]);

  useEffect(() => {
    params({
      currency: defaultCurrency,
      includeAmenities: amenities.toString(),
      maxPrice,
      minPrice,
      numberOfBathrooms,
      numberOfBedrooms,
      propertyType,
    });
  }, [minPrice, maxPrice, propertyType, amenities, numberOfBedrooms, params, defaultCurrency, numberOfBathrooms]);

  const content = (
    <div className={root}>
      <div className={priceWrapper}>
        <div className="d-flex align-items-center">
          <TextField className={cn(block, 'border-0')} color="input" bold onClick={() => setIsRangeCollapsed(false)}>
            <span>
              {t('Price per night:')}
              &nbsp;
            </span>
            {!minPrice && !maxPrice ? <span>{t('All')}</span> : <span>{`${minPriceText}-${maxPriceText}`}</span>}
          </TextField>
          {maxPrice || minPrice ? <Icon icon="close" size={12} onClick={resetPrice} /> : null}
        </div>

        <DropdownMenu isCollapsed={isRangeCollapsed} onDismiss={handleClosePriceFilter}>
          <div className={priceInput}>
            <Input
              value={minPrice}
              onChange={handleChangeMinPrice}
              type="number"
              placeholder="0"
              min={0}
              max={maxPrice}
              prefix={currencySymbol}
              error={maxPrice && minPrice > maxPrice ? t('Wrong min value') : ''}
            />
            <div className="p-2">-</div>
            <Input
              value={maxPrice}
              onChange={handleChangeMaxPrice}
              type="number"
              placeholder={t('Max')}
              min={minPrice}
              prefix={currencySymbol}
              error={maxPrice && maxPrice < minPrice ? t('Wrong max value') : ''}
            />
          </div>
        </DropdownMenu>
      </div>
      {activePropertyType && (
        <Dropdown
          value={propertyType}
          className={dropdownRoot}
          onChange={handleChangePropertyType}
          variant="single"
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {propertyTypeText}
              </TextField>
              {propertyType && <Icon icon="close" size={12} onClick={resetPropertyType} />}
            </div>
          }
        >
          <Option value={null}>{t('All')}</Option>
          {Object.keys(propertiesList).map((key) => (
            <Option value={key}>{propertyTypes[key]?.label}</Option>
          ))}
        </Dropdown>
      )}
      {activeAmenities && (
        <Dropdown
          value={amenities}
          className={dropdownRoot}
          variant="multiselect"
          onChange={handleChangeAmenities}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {amenitiesText}
                {amenities.length > 1 && ` +${amenities.length - 1}`}
              </TextField>
              {amenities.length ? <Icon icon="close" size={12} onClick={resetAmenities} /> : null}
            </div>
          }
        >
          {Object.keys(amenitiesList).map((key) => (
            <Option value={key}>{amenitiesList[key]?.label}</Option>
          ))}
        </Dropdown>
      )}
      {activeBedroom && (
        <Dropdown
          value={numberOfBedrooms}
          className={dropdownRoot}
          variant="single"
          withSelectAll
          onChange={handleChangeNumberOfBedrooms}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {numberOfBedroomsText}
              </TextField>
              {numberOfBedrooms ? <Icon icon="close" size={12} onClick={resetNumberOfBedrooms} /> : null}
            </div>
          }
        >
          <Option value={0}>{t('All')}</Option>
          {Array.from({ length: maxBedrooms }, (_, i) => i + 1).map((e) => (
            <Option value={e}>{e}</Option>
          ))}
        </Dropdown>
      )}
      {activeBathroom && (
        <Dropdown
          value={numberOfBathrooms}
          className={dropdownRoot}
          variant="single"
          withSelectAll
          onChange={handleChangeNumberOfBathrooms}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {numberOfBathroomsText}
              </TextField>
              {numberOfBathrooms ? <Icon icon="close" size={12} onClick={resetNumberOfBathrooms} /> : null}
            </div>
          }
        >
          <Option value={0}>{t('All')}</Option>
          {Array.from({ length: maxBathrooms }, (_, i) => i + 1).map((e) => (
            <Option value={e}>{e}</Option>
          ))}
        </Dropdown>
      )}

      {showPropertiesCount && (
        <TextField className={cn(block, total)} variant="h7" color="secondary">
          {`${totalListings || 0} ${t('Properties')}`}
        </TextField>
      )}

      <TextField className={cn(block, clearAll)} variant="h7" color="link" onClick={clearAllHandler}>
        {t('Clear all')}
      </TextField>
    </div>
  );

  return isMobile ? (
    <Accordion title={t('Filters')} className={accordion} open={open} onChange={() => setOpen(!open)}>
      {content}
    </Accordion>
  ) : (
    content
  );
};

export default SearchFilters;
