import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useHistory } from 'react-router-dom';

import { GuestyPayProvider } from '@guestyci/guesty-pay-widget';
import Spinner from '@guestyci/foundation/Spinner';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import useSearchValues from 'hooks/useSearchValues';
import FullPageLoader from 'components/FullPageLoader';

import useGetListingPaymentProvider from 'hooks/useGetListingPaymentProvider';
import useGetListingById from 'hooks/useGetListingById';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';

import CheckOutInstantStripe from './CheckOutInstantStripe';
import CheckInstantAmaryllis from './CheckOutInstantAmaryllis';

const STRIPE = 'stripe';
const AMARYLLIS = 'amaryllis';

const renderByProvider = ({ stripeObject, provider, providerId, property }) => {
  switch (provider) {
    case STRIPE:
      return (
        stripeObject && (
          <Elements stripe={stripeObject}>
            <CheckOutInstantStripe property={property} />
          </Elements>
        )
      );
    case AMARYLLIS:
      return (
        <GuestyPayProvider env={process.env.REACT_APP_ENV_CLUSTER}>
          <CheckInstantAmaryllis providerId={providerId} property={property} />
        </GuestyPayProvider>
      );
    default:
      return <Spinner />;
  }
};

const CheckOutInstantContainer = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { getPathWithLocale } = useGetPathToNavigate();
  const [stripeObject, setStripeObject] = useState(null);
  const redirectPath = getPathWithLocale('/properties');

  const [isGroupReservationsFTLoading, isGroupReservationsFTEnabled] = useFeatureToggle(GROUP_RESERVATIONS)

  if (isGroupReservationsFTEnabled) {
    if (!state?.quoteData?.quote?.length || !state?.ratePlan?._id) {
      history.push(redirectPath);
    }
  } else if (!state?.quoteId || !state?.ratePlanId) {
    history.push(redirectPath);
  }

  const { listingId } = useSearchValues();
  const { property, isGettingListing } = useGetListingById({ id: listingId });
  const { data: listingPaymentProvider, isLoading } = useGetListingPaymentProvider({ listingId });
  const { providerAccountId, providerType, _id: providerId } = listingPaymentProvider || {};

  useEffect(() => {
    const initStripe = async () => {
      if (providerAccountId) {
        const response = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
          stripeAccount: providerAccountId,
        });

        setStripeObject(response);
      }
    };
    initStripe();
  }, [providerAccountId]);

  if (isLoading || isGettingListing || isGroupReservationsFTLoading) {
    return <FullPageLoader />;
  }

  return renderByProvider({
    stripeObject,
    provider: providerType,
    providerId,
    property,
  });
};

export default CheckOutInstantContainer;
